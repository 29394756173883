const paymentData = {
    zone: {
        chunkId:'',
        value: '',
        status: true
    },
    duration: {
        byHour: true,
        value: {
            hours: 0,
            minutes: 0,
            days: 0
        },
        status: false
    },
    vehicle: {
        value: {
            country: 'US',
            state: 'FL',
            licensePlatNumber: ''
        },
        status: false
    },
    review: {
        value: {
            phoneNumber: '',
            amount: {
                total: 0
            }
        },
        status: false
    },
    
};
export default paymentData;
