import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SidebarItem = ({ item, selectedItemId, handleSideBarClick }) => {
    const { collapsedForm } = useSelector((state) => state.sidebar);

    return (
        <Link
            to={item.linkTo}
            className={`sidebar-item ${item.id === selectedItemId ? 'active-sidebar-item' : 'inactive-sidebar-item'}`}
            onClick={() => handleSideBarClick(item)}
        >
            <img src={item.iconLight} className="sidebar-item-icon" alt="sidebar-icon" />
            <span
                className={`sidebar-item-text ${collapsedForm ? 'hide-sidebar-item-text' : 'show-sidebar-item-text'}`}
            >
                {item.name}
            </span>
        </Link>
    );
};
export default SidebarItem;
