import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Sidebar, MainPanel } from '@components/Dashboard';
import Loading from '@components/Loading/Loading';
import Topbar from '@components/Dashboard/Topbar/Topbar';

const DashboardLayout = () => {
    const navigate = useNavigate();

    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/admin/login');
        }
    }, []);

    return (
        <>
            <Topbar />
            <Sidebar />
            <Suspense fallback={<Loading centered size="lg" />}>
                <MainPanel>
                    <Outlet />
                </MainPanel>
            </Suspense>
        </>
    );
};

export default DashboardLayout;
