import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    transitionType: 'collapse/expand',
    collapsedForm: false,
    hiddenForm: false,
    activeItem: 1
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        changeTransitionType: (state, { payload }) => {
            state.transitionType = payload;
        },
        changeCollapsedForm: (state, { payload }) => {
            state.collapsedForm = payload;
        },
        changeHiddenForm: (state, { payload }) => {
            state.hiddenForm = payload;
        },
        changeActiveItem: (state, { payload }) => {
            state.activeItem = payload;
        },
        setSidebarState: (state, { payload }) => {
            state.transitionType = payload.transitionType;
            state.collapsedForm = payload.collapsedForm;
            state.hiddenForm = payload.hiddenForm;
        }
    }
});

export const { changeTransitionType, changeCollapsedForm, changeHiddenForm, changeActiveItem, setSidebarState } =
    sidebarSlice.actions;
export default sidebarSlice.reducer;
