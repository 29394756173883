import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardLayout from '@layout/DashboardLayout/DashboardLayout';
import PublicLayout from '@layout/PublicLayout/PublicLayout';
import PaymentState from '../context/provider';

const Login = lazy(() => import('@pages/Auth/Login'));
const ForgotPassword = lazy(() => import('@pages/Auth/ForgotPassword/ForgotPassword'));
const OtpScreen = lazy(() => import('@pages/Auth/ForgotPassword/OtpScreen'));
const ResetPassword = lazy(() => import('@pages/Auth/ForgotPassword/ResetPassword'));
const Home = lazy(() => import('@pages/Home/Home'));
const AddLocation = lazy(() => import('@pages/AddLocation/AddLocation'));
const EditLocation = lazy(() => import('@pages/EditLocation/EditLocation'));
const Transactions = lazy(() => import('@pages/Transactions/Transactions'));
const Reports = lazy(() => import('@pages/Reports/Reports'));
const LPR = lazy(() => import('@pages/LPR/LPR'));
const UserProfile = lazy(() => import('@pages/UserProfile/UserProfile'));
const NotFound = lazy(() => import('@pages/NotFound/NotFound'));
const Zone = lazy(() => import('@pages/Parking/Zone/Zone'));
const Duration = lazy(() => import('@pages/Parking/Duration/Duration'));
const Vehicle = lazy(() => import('@pages/Parking/Vehicle/Vehicle'));
const Review = lazy(() => import('@pages/Parking/Review/Review'));
const Success = lazy(() => import('@pages/Parking/Success/Success'));

const MainRoutes = () => {
    return (
        <PaymentState>
            <Routes>
                <Route path="/admin" element={<PublicLayout />}>
                    <Route path="login" exact element={<Login />} />
                    <Route path="forgot-password" exact element={<ForgotPassword />} />
                    <Route path="forgot-password/verify-otp" exact element={<OtpScreen />} />
                    <Route path="forgot-password/reset-password" exact element={<ResetPassword />} />
                </Route>

                <Route path="/">
                    <Route index exact element={<Zone />} />
                    <Route path="zone" exact element={<Zone />} />
                    <Route path="zone/:zoneID" exact element={<Zone />} />
                    <Route path="duration" exact element={<Duration />} />
                    <Route path="vehicle" exact element={<Vehicle />} />
                    <Route path="review" exact element={<Review />} />
                    <Route path="success" exact element={<Success />} />
                </Route>

                {/* protected layout */}
                <Route path="/admin" element={<DashboardLayout />}>
                    <Route index exact element={<Home />} />
                    <Route path="add-location" exact element={<AddLocation />} />
                    <Route path="edit-location/:locationId" exact element={<EditLocation />} />
                    <Route path="transactions" exact element={<Transactions />} />
                    <Route path="reports" exact element={<Reports />} />
                    <Route path="lpr" exact element={<LPR />} />
                    <Route path="profile" exact element={<UserProfile />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </PaymentState>
    );
};

export default MainRoutes;
