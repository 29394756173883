import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import LogoutModal from '../../../LogoutModal/LogoutModal';
import './profiledropdown.scss';
import { useSelector } from 'react-redux';

const ProfileDropdown = () => {
    const { userInfo } = useSelector((state) => state.auth);
    //state to handle the modal
    const [showLogoutModal, setLogoutModal] = useState(false);
    const handleLogoutModal = () => setLogoutModal((pre) => !pre);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const toggleDropdown = () => {
        setShow(!show);
    };

    function navigator() {
        navigate('/admin/profile');
    }

    return (
        <Dropdown className="profile-dropdown" onToggle={toggleDropdown}>
            <Dropdown.Toggle id="profile-dropdown-toggle">
                <span>{userInfo?.name}</span>
                <FontAwesomeIcon className={show ? 'angle-up' : ''} icon={faAngleDown} />
            </Dropdown.Toggle>
            <Dropdown.Menu show={show} id="profile-dropdown-menu">
                <Dropdown.Item onClick={navigator}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleLogoutModal}>Logout</Dropdown.Item>
            </Dropdown.Menu>
            <LogoutModal onClick={handleLogoutModal} isOpen={showLogoutModal} handleModal={handleLogoutModal} />
        </Dropdown>
    );
};

export default ProfileDropdown;
