import * as Sentry from '@sentry/react';
import './errorBoundary.scss';

export function ErrorBoundaryComponent({ error }) {
    // Report the error to Sentry
    Sentry.captureException(error);

    return (
        <div className="error_boundary">
            <img src="/warning.png" alt="stat-icon" />
            <p>Oops! Something went wrong.</p>
        </div>
    );
}
