import { PaymentContext } from './context';
import { useState } from 'react';
import paymentData from './payment/processPayment';

const PaymentState = (props) => {
    const [paymentProcessor, setPaymentProcessor] = useState(paymentData);
    const data = {
        paymentProcessor,
        setPaymentProcessor
    };
    return <PaymentContext.Provider value={data}>{props.children}</PaymentContext.Provider>;
};
export default PaymentState;
